:root {
    --dark-bg-color: #2a1d27;
}

html, body, #root {
    height: 100dvh;
    overflow: hidden;
}

html, body {
    background-color: var(--dark-bg-color) !important;
    color: white;
}

a {
    text-decoration: none !important;
    color: inherit !important;
}
